import React, {
  useEffect, useRef,
} from 'react'
import { Grid } from '@material-ui/core'

import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'
import { MediaTypes } from '../HeroHeader/HeroHeader'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'

export interface MediaBlockProps {
  mobile: boolean;
  mediaContainerClass: string;
  mediaType: MediaTypes;
  imageAlt?: string;
  mediaSrc?: string;
  title?: string;
  mobileMediaSrc?: string;
  height?: string;
  width?: string;
  controls?: boolean;
  media_image?: MediaImageProps;
}

export default function MediaBlock({
  mobile,
  mediaContainerClass,
  mediaSrc,
  mediaType,
  imageAlt,
  mobileMediaSrc,
  height,
  width,
  controls,
  media_image,
}: MediaBlockProps) {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [isVisible] = useIntersectionObserver({ elementRef: videoRef })

  useEffect(() => {
    if (videoRef?.current && !isVisible && controls) {
      videoRef.current.pause()
    }
  }, [isVisible])

  return (
    <Grid item xs={12} md={6} className={mediaContainerClass}>
      {mediaType === 'video' && (
        <video ref={videoRef} width="100%" height="100%" loop={!controls} muted={!controls} autoPlay={!controls} playsInline={!controls} controls={controls}>
          <source src={mobile && mobileMediaSrc ? mobileMediaSrc : mediaSrc} type="video/mp4"/>
          <track kind="captions"/>
        </video>
      )}
      {mediaType === 'image' && (
        <>
          {mediaSrc &&
          <picture>
            <source srcSet={mediaSrc} media="(min-width: 959px)"/>
            <img src={mobileMediaSrc} alt={imageAlt} height={height} width={width}/>
          </picture>}
          {media_image?.src &&
          <MediaImage
            src={media_image.src}
            width={media_image.width || 576}
            height={media_image.height || 480}
            alt={media_image.alt}
            mobile_src={media_image.mobile_src}
            mobile_width={media_image.mobile_width}
            mobile_height={media_image.mobile_height}
            center
          />}
        </>
      )}
    </Grid>
  )
}
