import React, {
  useEffect, useState,
} from 'react'
import Header from '../Header/Header'
import HeroHeader, { HeroHeaderProps } from '../HeroHeader/HeroHeader'
import IconsWithText, {
  Item, ButtonType, BackgroundColorTypes,
} from '../IconsWithText/IconsWithText'
import {
  ButterPage, getButterComponentByType, getButterPage,
} from '../../butter'

export default function ErrorPage({
  meta, title, title_second_line, subtitle, text, media_src, mobile_media_src, media_type,
}: HeroHeaderProps) {
  const [
    pageData,
    setPageData,
  ] = useState<ButterPage>({
    components: [],
    title: '',
  })

  useEffect(() => {
    async function fetchPageData() {
      try {
        const data = await getButterPage('not-found', {})
        setPageData(data)
      } catch (err) {
        console.error('Error fetching page data:', err)
      }
    }

    fetchPageData()
  }, [])

  const icons: Item[] = [
    {
      title: 'To purchase our packages  ',
      image: 'https://cdn.buttercms.com/T6vqEiCURiyl6Qbgju3D',
      text: '<p><strong>Call us on: <span style="color: #e67e23;">0808 196 6262</span></strong></p>\r\n<p>Monday - Friday: 9am - 8pm</p>\r\n<p>Saturday: 9am - 5pm</p>',
      icon_url: '',
    },
    {
      title: 'For Customer Service ',
      image: 'https://cdn.buttercms.com/cVQoKic0QySuY9vMVDcI',
      text: '<p><strong>Call us on: <span style="color: #e67e23;">0800 082 0770</span></strong></p>\r\n<p>Monday - Friday: 8am - 9pm</p>\r\n<p>Weekends: 9am - 7pm</p>',
      icon_url: '',
    },
  ]
  if (!pageData) {
    return (
      <>
        <Header/>
        <main>
          <HeroHeader
            meta={meta}
            title={title}
            title_second_line={title_second_line}
            subtitle={subtitle}
            text={text}
            media_src={media_src}
            mobile_media_src={mobile_media_src}
            media_type={media_type}
          />
          <IconsWithText
            title="Need help?"
            body=""
            items={icons}
            mobile_carousel={false}
            background_colour={BackgroundColorTypes.white}
            anchor_ref=""
            button_expand_text="Expand"
            button_shrink_text="Shrink"
            button_type={ButtonType.solid}
            show_all_icons
          />
        </main>
      </>
    )
  }

  return (
    <main>
      {pageData.components.map(({
        type, ...rest
      }, i) => {
        const C: any = getButterComponentByType(type)
        if (!C) {
          return null
        }

        if (type === 'menu') {
          return (
            <C
              key={i}
              {...rest}
            />
          )
        }

        if (type === 'hero_section') {
          return (
            <C
              key={i}
              {...rest}
            />
          )
        }

        if (type === 'icons_with_text') {
          return (
            <C
              key={i}
              {...rest}
            />
          )
        }

        return (
          <C key={i} {...rest}/>
        )
      })}
    </main>
  )
}
