import React from 'react'
import ErrorPage from './ErrorPage'
import {
  HeroHeaderProps, MediaTypes,
} from '../HeroHeader/HeroHeader'

const heroHeader: HeroHeaderProps = {
  meta: { id: 502 },
  title: 'Oops!',
  title_second_line: 'Well, this is unexpected...',
  subtitle: 'An error has occurred and we\'re working to fix the problem. We\'ll be up and running shortly. ',
  media_src: 'https://cdn.buttercms.com/l9LGbfUTOyxjJBDjX91l',
  mobile_media_src: 'https://cdn.buttercms.com/l9LGbfUTOyxjJBDjX91l',
  media_type: MediaTypes.image,
}

export default function SomethingWentWrongPage() {
  return <ErrorPage {...heroHeader}/>
}
