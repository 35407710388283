import React, { PureComponent } from 'react'

import {
  ButterCMSComponent, getButterPage,
} from '../butter'
import App from '../components/App/App'
import { isAxiosError } from '../utils/err'
import Error from './_error'

type SlugProps = {
  components: ButterCMSComponent[];
  title: string;
  description?: string;
  errorCode?: number;
  keywords?: string;
  path: string;
}

export default class Slug extends PureComponent<SlugProps> {
  static async getInitialProps({
    res, query,
  }: any) {
    try {
      const path = (query.slug || []).join('/')

      const data = await getButterPage(path, query)
      return {
        ...data,
        path,
      }
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        if (res) {
          res.statusCode = err.response.status
        }

        return {
          errorCode: err.response.status,
        }
      }

      throw err
    }
  }

  render() {
    const {
      components,
      description,
      errorCode,
      keywords,
      title,
    } = this.props

    if (errorCode) {
      return <Error statusCode={errorCode}/>
    }

    return (
      <App components={components} description={description} keywords={keywords} title={title}/>
    )
  }
}

