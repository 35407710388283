import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

export const imageSizes = Object.freeze({
  desktop: {
    width: 576,
    height: 480,
  },
  tablet: {
    width: 400,
    height: 360,
  },
  mobile: {
    width: 343,
    height: 177,
  },
})

const classes = {
  section: css`
    text-align: center;
    background: ${theme.palette.warning.light};
  `,
  logo: css`
    width: auto;
    ${theme.breakpoints.up('sm')} {
      height: auto;
      max-width: 100%;
    }
    ${theme.breakpoints.down('sm')} {
      height: 38px;
      max-width: 240px;
    }
  `,
  parent: css`
    display: flex;
    flex-direction: row-reverse;
    &.MuiGrid-container {
      border-radius: ${commonStyling.roundedBorders.all};
      margin: 0 auto;
      height: inherit;
      background: ${theme.palette.warning.light};
      align-items: center;
    }
    picture {
      display: block;
    }
  `,
  infoContainer: css`
    padding: ${theme.spacing(1, 0, 4)};
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(7, 12, 7, 0)};
      text-align: left;
    }
  `,
  caption: css`
    color: ${theme.palette.primary.main};
    text-transform: uppercase;
    padding-bottom: ${theme.spacing(1)}px;
  `,
  title: css`
    color: ${theme.palette.primary.main};
    ${theme.breakpoints.up('md')} {
      &.MuiTypography-root {
        font-size: ${fontSizes.h1};
      }
    }
    div {
      font-size: ${fontSizes.h2};
      margin-top: ${theme.spacing(1.5)}px;
    }
  `,
  subtitle: css`
    padding: ${theme.spacing(2, 0, 3)};
    color: ${COLORS.bodyTextPrimary};
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  trustpilotWrapper: css`
    ${theme.breakpoints.down('sm')} {
      iframe {
        margin-left: auto;
        margin-right: auto;
      }
    }
 `,
  mediaContainer: css`
    padding: ${theme.spacing(3, 0, 1)};
    border-radius: ${commonStyling.roundedBorders.all};
    img,
    video {
      max-width: 100%;
    }
    img {
      height: 100%;
      max-height: 480px;
      object-fit: contain;
      ${theme.breakpoints.up('md')} {
        max-width: 100%;
      }
      ${theme.breakpoints.only('md')} {
        height: 360px;
      }
      ${theme.breakpoints.only('sm')} {
        height: 300px;
      }
      ${theme.breakpoints.down('xs')} {
        height: 200px;
      }
    }
    ${theme.breakpoints.up('md')} {
      display: flex;
      justify-content: center;
      padding: ${theme.spacing(9, 0, 8)};
      img,
      video {
        max-height: 480px;
      }
    }
  `,
  primaryButton: css`
    &.MuiButtonBase-root {
      margin: ${theme.spacing(0, 0, 2)};
      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(1, 0, 3)};
      }
      .MuiButton-label {
        img {
          margin-left: 11px;
          position: relative;
          top: 3px;
          animation: bounce-icon .4s infinite alternate;
        }
        @keyframes bounce-icon {
          from {
            transform: translateY(2px);
          }
          to {
            transform: translateY(-5px);
          }
        }
      }
    }
  `,
}

export default classes
