import React from 'react'
import ErrorPage from './ErrorPage'
import {
  HeroHeaderProps, MediaTypes,
} from '../HeroHeader/HeroHeader'

const heroHeader: HeroHeaderProps = {
  meta: { id: 404 },
  title: 'Oh no!',
  title_second_line: '404 - Page not found',
  subtitle: 'We’re sorry. It looks like the page you’re after doesn’t exist.',
  text: '<p>How did you end up here?</p><ul><li>The URL may have been mistyped</li><li>The page may have been deleted or moved</li><li>The link may be broken</li></ul><p><b>Not to worry!</b> Click on logo to return to the homepage.</p>',
  media_src: 'https://cdn.buttercms.com/Me9aJqJTambQy35ZwBIG',
  mobile_media_src: 'https://cdn.buttercms.com/Me9aJqJTambQy35ZwBIG',
  media_type: MediaTypes.image,
}

export default function SomethingWentWrongPage() {
  return <ErrorPage {...heroHeader}/>
}
