import React, {
  useEffect, useRef,
} from 'react'
import {
  Grid, Link, Typography,
} from '@material-ui/core'
import classes from './HeroHeader.styles'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import SearchBar, { SearchBarProps } from '../SearchBar/SearchBar'
import TrustPilotBox, { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'
import { useGeneralContext } from '../GeneralContext/GeneralContext'
import { CtaProps } from '../../utils/commonProps'
import StyledButton from '../StyledButton/StyledButton'
import InfoExpansion from '../TextWithMedia/InfoExpansion'
import MediaBlock from '../MediaBlock/MediaBlock'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import { LinkProps } from '../Packages/Packages'

export enum MediaTypes {
  image = 'image',
  video = 'video'
}

export interface HeroHeaderProps {
  readonly meta?: {
    id: number;
  };
  caption?: string;
  title?: string;
  title_second_line?: string;
  logo?: string;
  subtitle?: string;
  text?: string;
  media_src: string;
  mobile_media_src: string;
  media_type: MediaTypes;
  image_alt_text?: string;
  bottom_link?: LinkProps;
  search_bar?: SearchBarProps;
  trustpilot_box?: TrustPilotBoxProps;
  button?: CtaProps;
  expansion_text?: string;
  expansion_title?: string;
  hide_video_controls?: boolean;
  media_image?: MediaImageProps;
}

export default function HeroHeader({
  meta,
  caption,
  title,
  logo,
  subtitle,
  text,
  title_second_line,
  media_src,
  mobile_media_src,
  media_type,
  image_alt_text,
  bottom_link,
  search_bar,
  trustpilot_box,
  button,
  expansion_text,
  expansion_title,
  hide_video_controls,
  media_image,
}: HeroHeaderProps) {
  const headerRef = useRef<HTMLDivElement | null>(null)

  const [isVisible] = useIntersectionObserver({
    elementRef: headerRef,
  })

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  useEffect(() => {
    setGeneralOptions({
      ...generalOptions,
      showStickyCheckAvailability: !isVisible,
    })
  }, [])

  const show_searchBar = search_bar && Object.keys(search_bar).length > 0

  return (
    <section className={classes.section} ref={headerRef} id={`HeroHeader_${meta?.id}`}>
      <ContainerWithPadding>
        <Grid className={classes.parent} container>
          <MediaBlock
            mobile mediaContainerClass={classes.mediaContainer}
            mediaSrc={media_src} mediaType={media_type} imageAlt={image_alt_text}
            mobileMediaSrc={mobile_media_src} controls={!hide_video_controls}
            media_image={media_image}
            height="360" width="100%"
          />
          <Grid item xs={12} md={6} className={classes.infoContainer}>
            {caption &&
              <Typography variant="body2" className={classes.caption}>
                {caption}
              </Typography>}
            {logo &&
              <MediaImage
                src={logo}
                width={413}
                height={65}
                alt="Community Fibre TV"
              />}
            {title &&
              <Typography variant="h1" className={classes.title}>
                {title}
                {title_second_line && <div>{title_second_line}</div>}
              </Typography>}
            {subtitle &&
              <Typography variant="body1" className={classes.subtitle}>
                {subtitle}
              </Typography>}
            {text &&
              <Wysiwyg body={text} variant="body2"/>}
            {bottom_link &&
              <>
                <Link color="textSecondary" variant="body2" href={bottom_link.href} underline="always">{bottom_link.label}</Link>
                <br/>
              </>}
            {(expansion_text && expansion_title) && <InfoExpansion text={expansion_text} title={expansion_title}/>}
            {show_searchBar && (
              <Grid item id="searchbarWrapper">
                <SearchBar
                  {...search_bar}
                  placeholder_text={search_bar!.placeholder_text}
                  placeholder_mobile_text={search_bar!.placeholder_mobile_text}
                  search_button_text={search_bar!.search_button_text}
                  loading_text={search_bar!.loading_text}
                  no_address_found_text={search_bar!.no_address_found_text}
                  error_text={search_bar!.error_text}
                />
              </Grid>)}
            {button && button.text &&
              <StyledButton isModal={button.modal} color="primary" url={button.url} className={classes.primaryButton}>
                {button.text}
                {button.icon && <img src={button.icon} alt=""/>}
              </StyledButton>}
            {trustpilot_box && trustpilot_box.widget &&
            <TrustPilotBox
              styles={classes.trustpilotWrapper}
              background_color={trustpilot_box.background_color}
              widget={trustpilot_box.widget}
              padding={trustpilot_box.padding}
              margin={trustpilot_box.margin}
              wrapped={trustpilot_box.wrapped}
              inverted={trustpilot_box.inverted}
              width={trustpilot_box.width}
              offset={trustpilot_box.offset}
              mobile_center={trustpilot_box.mobile_center}
            />}
            <Grid/>
          </Grid>
        </Grid>
      </ContainerWithPadding>
    </section>
  )
}
