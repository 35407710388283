import React, { useEffect } from 'react'
import { NextPageContext } from 'next'
import { ThemeProvider } from '@material-ui/styles'
import theme from '../styles/theme'
import SomethingWentWrongPage from '../components/ErrorPage/SomethingWentWrongPage'
import NotFoundPage from '../components/ErrorPage/NotFoundPage'
import marketingEvents from '../utils/marketing/marketingEvents'
import { useOrderContext } from '../components/NewOrderFlow/OrderContext'

function Error({ statusCode }: {statusCode: number}) {
  const {
    options,
  } = useOrderContext()

  useEffect(() => {
    const errorMsg = 'Landed on <Error> Page'
    marketingEvents.error(options, `ERR-STATUS-${statusCode}`, 'Error page', errorMsg)
  }, [])

  if (statusCode === 404) {
    return (
      <ThemeProvider theme={theme}>
        <NotFoundPage/>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <SomethingWentWrongPage/>
    </ThemeProvider>
  )
}

Error.getInitialProps = ({
  err, res,
}: NextPageContext) => {
  if (err && err.statusCode) {
    return { statusCode: err.statusCode }
  }

  if (res && res.statusCode) {
    return {
      statusCode: res.statusCode,
    }
  }

  return {
    statusCode: 404,
  }
}

export default Error
